import React from "react";
import Img from "gatsby-image";
import { FaPaw, FaEtsy, FaInstagram, FaFacebookSquare } from "react-icons/fa";
import { Helmet } from "react-helmet";

import styles from "./index.module.css";

export default ({ data }) => (
  <div className={styles.app}>
    <Helmet>
      <meta charset="utf-8" />

      <title>Alexa Taylor</title>
      <meta name="description" content="Portfolio page for Alexa Taylor" />
      <meta name="author" content="Alexa Taylor" />
      <meta name="robots" content="index, follow" />
    </Helmet>
    {/* <header className={styles.header}>Header</header> */}
    <section className={styles.body}>
      <Img
        className={styles.imgWrapper}
        fluid={data.file.childImageSharp.fluid}
      />
      <div className={styles.name}>Alexa Taylor</div>
      <p className={styles.text}>
        Artist with a passion for travel and wildlife
      </p>
      <div className={styles.icons}>
        <a href="https://portsidepaws.com" className={styles.linkIcon}>
          <FaPaw className={styles.iconPP} />
        </a>
        <a
          href="https://www.etsy.com/in-en/shop/PortsidePaws"
          className={styles.linkIcon}
        >
          <FaEtsy className={styles.iconET} />
        </a>
        <a
          href="https://www.instagram.com/alexataylor89/"
          className={styles.linkIcon}
        >
          <FaInstagram className={styles.iconIG} />
        </a>
        <a
          href="https://www.linkedin.com/in/nialllennon2/"
          className={styles.linkIcon}
        >
          <FaFacebookSquare className={styles.iconFB} />
        </a>
      </div>
    </section>
  </div>
);

export const query = graphql`
  query {
    file(relativePath: { eq: "alexa.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
